#news {
    width: 100%;
    margin: 0%;
    padding: 0%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.newsCont {
    width: 100%;
    margin: 0%;
    padding: 0%;
    display: flex;
    flex-wrap: wrap;
}

.news-element {
    width: 25%;
    min-width: 220px;
    /* background: #c0c2f8; */
    box-shadow: 6px 5px 8px 4px #8d8d8d;
    margin: 2% 4%;
    padding: 0%;
    display: inline-block;
    transition-duration: 0.5s;
    cursor: pointer;
}

.news-element:hover{
    filter: brightness(0.6);
}
.news-element img {
    width: 100%;
    /* height: 80%; */
}
.newsHeader {
    color: #7052CA;
    text-align: center;
    margin-top: 0;
}

.description p {
    margin: 2% 5%;
}

@media screen and (max-width: 890px) {
    .news-element {
        margin: 2% 11%;
    }
  }


@media screen and (max-width: 780px) {
    .news-element {
        margin: 2% 15%;
        width: 70%;
    }
    
  }

.wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.notFound {
    color: #7052CA;
}
.news-area-element {
    width: 80%;
    min-width: 220px;
    /* background: #c0c2f8; */
    box-shadow: 6px 5px 8px 4px #8d8d8d;
    margin: 2% 4%;
    padding: 5%;
    display: inline-block;
    transition-duration: 0.5s;
    cursor: pointer;
    min-width: 220px;
    height: auto;
}
.news-area-content{
    width: 96%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.newsAreaElementP {
    font-size: 1.2em;
}

.videoPlayer {
    width: auto;
    height: auto;
    max-width: 1000px;
}
.videoPlayer video {
    width: auto;
    height: 100%;
    max-width: 100%;
}

.playerConteiner {
    width: auto;
    height: 100%;
    background: black;
    position: relative;
}

.playBtn {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 37%;
    top: 50%;
}