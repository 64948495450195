.aboutUsContent {
    text-align: center;
    width: 100%;
}
.features {
    text-align: left;
    width: 80%;
    margin: auto;
    margin-bottom: 2%;
}
.aboutUsHeader {
    color: #7052CA;
} 

.teamMembers {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
