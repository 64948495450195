.contacts {
    width: 100%;
    margin: 0;
    padding: 0%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 5%;
}

.contact {
    width: 300px;
    height: auto;
}

.contact img {
    width: 100%;
}