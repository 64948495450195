html, body, #root {
  min-height: 100%;
  display: flex;
  flex: 1;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  width: 100%;
  min-height: 100%;
  /* background-color: #190026; */
}

.App-bg-wrapper {
  width: 100%;
  height: fit-content;
}

.App-bg {
  width: 100%;
  height: auto;
}

.ok {
  cursor: pointer;
  width: 3.3%;
  position: absolute;
  right: 32.6%;
}

.fb {
  cursor: pointer;
  width: 3.3%;
  position: absolute;
  right: 37.1%;
}

.googlePlay {
  cursor: pointer;
  width: 10.2%;
  position: absolute;
  right: 20.7%;
}

.appStore {
  width: 10.2%;
  cursor: pointer;
  position: absolute;
  right: 9.7%;
}

/****************************************************SLIDE SHOW***************************************************/

.slideshow-container {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.dots {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
}
/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  transform: scale(1.3,1.7);
  background-color: rgba(0,0,0,0.3);
}
.prev {
  left: 0%;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
}

@media only screen and (max-width: 700px) {
  .fade img {
    transform: scale(1.5,1.5);
    margin-top: 10%;
  }
}

/****************************************************NAV BAR***************************************************/

.topnav {
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
}

.topnav a {
  float: left;
  display: block;
  color: #000000;
  text-align: center;
  padding: 15px 18px;
  text-decoration: none;
  font-size: 17px;
  transition: 0.2s;
  transition-duration: 0.2s;
  cursor: pointer;
}
.topnav a img {
  width: 80px;
  height: 51px;
}
.topnav a:hover {
  color: rgb(79, 65, 155);;
}

.topnav a.active {
  background-color: rgb(79, 65, 155);
  color: white;
  width: 75px;
  height: auto;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 745px) {
  .topnav a:not(:first-child) {display: none;}
  .topnav a.icon {
    float: right;
    display: block;
    transform: scale(1.5);
  }
}

@media screen and (max-width: 745px) {
  .topnav {
    justify-content: space-between;
  }
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

select {
  background: white;
  border: none;
  font-size: 16px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  padding: 4%;
  color: black;
  background: none;
  font-family: sans-serif;
}
select option {
  color:  black;
  background: none;
}

@media screen and (max-width: 600px) {

  select {
    color: white;
  }
}
/****************************************************SLIDE NAV BAR***************************************************/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #050405f0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #bdbdbd;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
/****************************************************LINKS***************************************************/

.linksCont {
  width: 40%;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  margin: auto;
  position: absolute;
  top: 88%;
  margin-left: 30%;
}

.link {
  width: 10vw;
  height: auto;
  cursor: pointer;
}
.link.fbok{
  width: 4vw;
}

.link img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 700px) {
  .linksCont {
    width: 70%;
    margin-left: 15%;
    position: relative;
    margin-top: 3%;
  }
}
@media screen and (max-width: 300px) {
  .linksCont {
    margin-top: -1%;
  }
}

/****************************************************FOOTER***************************************************/

.footer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  position: relative;
  overflow: hidden;
  background-color: #000000f0;
  text-align: center;
}
.categories {
  width: 85%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.footer .footerBg {
  position: absolute;
  filter: blur(1px);
  opacity: 0.6;
}


.content {
  width: 33%;
  justify-content: flex-start;
  color: white;
}
.paragraph1 {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.paragraph2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paragraph1 .footerLink img {
  width: 80px;
  height: auto;
}
.paragraph2 .footerLink img {
  width: 200px;
  height: auto;
  display: flex;
}
.footerLink {
  margin: 3% -12%;
}

@media screen and (max-width: 800px) {
  .categories {
    flex-direction: column;
    align-items: center;
  }
  .paragraph1 {
    justify-content: space-evenly;
  }
  .paragraph2 {
    justify-content: space-evenly;
    align-items: center;
  }
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 440px) {
  .paragraph2 {
    flex-direction: column;
    align-items: center;
  }
}

.aboutUs section{
  text-align: left;
  transition: 0.2s;
  transition-duration: 0.2s;
  font-size: 1.1em;
}
.aboutUs {
  transition: 0.2s;
  transition-duration: 0.2s;
}

#showMoreContent {
  transform:scale(1,0);
  display: none;
  transition: 0.2s;
  transition-duration: 0.2s;
}

.btn {
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    color: black;
    border-bottom: 1px solid;
    padding: 0px;
    font-size: inherit;
    margin: 5px auto;
    cursor: pointer;
    display: flex;
    outline: none;
    color: #4712ff;
}
#showLess {
  display: none;
}
.contactContent {
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
}


.contactContent i {
  width: 30%;
  height: auto;
}
.contactContent i::before {
  font-size: 60px;
}
@media screen and (max-width: 400px) {
  .paragraph1 {
    width: 100%;
  }
}
@media screen and (max-width: 330px) {
  .contactContent {
    flex-direction: column;
    align-items: center;
  }
  .paragraph1 {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

/****************************************************GAME TYPES***************************************************/

.gameTypesCont {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.typesConteiner {
  width: 100%;
  color: black;
  margin: 0%;
  padding: 0%;
  flex-wrap: wrap;
  display: flex;
}
.gameType .content {
  width: 100%;
  justify-content: flex-start;
  color: white;
}

.content img {
  width: 100%;
}
.gameType {
  width: 40%;
  margin: 0% 5%;
  padding: 0%;
  display: inline-block;
}

.description {
  color: #000000;
  display: inline-block;
  width: 100%;
} 

.gameTypeHeader {
  color: #7052CA;
}

@media screen and (max-width: 500px) {
  .typesConteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
  } 
  .gameType {
    width: 70%;
  }
}

/****************************************************LAST NEWS***************************************************/

.slider-conteiner{
  margin: 0 0 0px;
  zoom: 1;
}

.slider-viewport{
  overflow: hidden;
  position: relative;
  height: 30vw;
  background-color: #ac4def;
  display: flex;
  align-items: center;
  background-size: cover;
}
.slides{
  width: auto;
  transition-duration: 1s;
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
}
.flexslider .slides img {
  width: 100%;
  display: block;
  position: relative;
}

.slides li{
  border: 0.5vw solid hsla(223, 82%, 74%, 0.39);
  background: rgba(186, 182, 202, 0.74);
  margin-right: 5vw;
  z-index: 2;
  width: 31vw;
  float: left;
  display: block;
  height: 18vw;
  /* opacity: 0.8; */
  position: relative;
  color: white;
}

.slides li img {
  left: 0;
  position: absolute;
  width: 25%;
  height: auto;
  top: 0;
}
.caption-info-head-left  img{
  padding-left: 40px;
}
.caption-info-head-left a{
  text-decoration: none;
  color: #747474;
  font-size: 0.95em;
  font-weight: 400;

}
.caption-info-head-left span{
 
  color: #747474;
  font-size: 0.95em;
  font-weight: 400;

}
.slides li:hover{
  transition: 0.2s;
  border-color: rgb(98, 44, 224);
}

.slides li span {
    font-size: 1.8vw;
    width: 75%;
    position: absolute;
    top: 0;
    text-align: center;
    left: 25%;
    height: auto;
    color: #190026;
}

.caption-info-head-left  {
    font-size: 1.6vw;
    width: 75%;
    position: absolute;
    top: 0;
    text-align: center;
    left: 25%;
    height: 80%;
    margin-top: 12%;
    display: inline-block;
}
.caption-info-head-left p {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    text-align: center;
    align-items: center;
}
 
