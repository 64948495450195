.header{
    width: 100%;
}
.barsCont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
    height: 4vh;
}

.header img{
    background-color: rgb(79, 65, 155);
    padding: 0.9vw;
    width: 10%;
    height: 100%;
}

.barsCont a i::before{
    font-size: 6vw;
    margin-right: 2vw;
}

.mainHeader {
    width: 100%;
    height: 32vw;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-position-x: center;
    background-position-y: center;
}

@media screen and (max-width: 780px) {
    .barsCont{
        display: flex;
    }
}

.nav {
    align-items: center;
    display: flex;
    width: 50%;
    justify-content: space-between;
    margin:2% 5%;
    transition-duration: 0.3s;
}

.nav a {
    color: #EEEDFA;
    text-decoration: none;
}

.nav a span {
    text-shadow: 2px 4px 10px #00bcff;;
} 


@media screen and (max-width: 1250px) {
    .nav{
        width: 65%;
    }
}


@media screen and (max-width: 950px) {
    .nav{
        width: 75%;
    }
}

@media screen and (max-width: 780px) {
    .nav{
        display: none;
    }
}
